import React, { lazy, Suspense } from 'react'
import { Box } from '@material-ui/core'
import { BrowserRouter, Route, Switch, RouteComponentProps } from 'react-router-dom'

// Components
import Transition from './components/basic/Transition'
import Loading from './components/basic/Loading'
import Home from './views/Home'

const Admin = lazy(() => import(/* webpackChunkName: "Admin" */ /* webpackMode: "lazy" */ './views/Admin'))
const Result = lazy(() => import(/* webpackChunkName: "Result" */ /* webpackPrefetch: true */ './views/Result'))

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Box height='100vh' width='100vw' overflow={{ md: 'auto', lg: 'hidden' }}>
        <Route
          render={(props: RouteComponentProps): React.ReactNode => {
            return (
              <Transition location={props.location.pathname}>
                <Suspense fallback={<Loading />}>
                  <Switch location={props.location}>
                    <Route path='/' exact>
                      <Home />
                    </Route>
                    <Route path='/result'>
                      <Result />
                    </Route>
                    <Route path='/admin'>
                      <Admin />
                    </Route>
                  </Switch>
                </Suspense>
              </Transition>
            )
          }}
        />
      </Box>
    </BrowserRouter>
  )
}

export default App
