import { Box, BoxProps, Typography } from '@material-ui/core'
import React from 'react'

// Style
import { useStyles } from './useStyles'

type ErrorMessageProps = {
  message: string
  top?: string
  left?: string
  show: boolean
  boxProps?: BoxProps
}

const ErrorMessage = ({ show, message, left = '12px', top = '42px', boxProps = {} }: ErrorMessageProps): React.ReactElement | null => {
  const classes = useStyles()

  return show ? (
    <Box position='absolute' left={left} top={top} {...boxProps}>
      <Typography color='error' component='span' variant='body2' className={classes.root}>
        {message}
      </Typography>
    </Box>
  ) : null
}

export default ErrorMessage
