import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const color = {
  primary: {
    main: '#173B98',
    dark: '#3B4869',
    light: '#44ADFF',
    contrastText: '#1A2037',
  },
  secondary: {
    main: '#1A2B4D',
    light: '#A2A7B9',
    dark: '#497BC2',
    contrastText: '#F5F7FB',
  },
}

const theme = createMuiTheme({
  palette: {
    ...color,
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    htmlFontSize: 12,
    h1: {
      fontSize: '47px',
      fontFamily: 'Anton, sans-serif',
      color: color.secondary.main,
      fontWeight: 400,
      lineHeight: 1.235,
    },
    h2: {
      fontSize: '35px',
      fontWeight: 400,
      fontFamily: 'Anton, sans-serif',
      lineHeight: 1.34,
    },
    h3: {
      fontWeight: 500,
      fontSize: '25.5px',
      fontFamily: 'Archivo Narrow, sans-serif',
      lineHeight: 1.6,
    },
    h4: {
      fontWeight: 'bold',
      lineHeight: 1.6,
      fontSize: '26px',
    },
    h5: {
      fontWeight: 'bold',
      fontSize: '24px',
      color: '#A0EFFF',
      marginBottom: '26px',
      marginLeft: '30px',
      position: 'relative',
      lineHeight: '28px',

      '&::after': {
        content: '"|"',
        fontWeight: 400,
        position: 'absolute',
        top: '0',
        right: 'calc(100% + 25px)',
      },
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#fff',
    },
    subtitle2: {
      fontSize: '14px',
      lineHeight: '26px',
      fontWeight: 500,
      color: '#fff',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          {
            fontFamily: 'Anton',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 400,
            src: `
            local('Anton Regular'), 
            local('Anton-Regular'), 
            url(https://fonts.gstatic.com/s/anton/v12/1Ptgg87LROyAm3Kz-C8CSKlv.woff2) format('woff2')
          `,
            unicodeRange:
              'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
          },
          {
            fontFamily: 'Archivo Narrow',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 500,
            src: `
            local('Archivo Narrow Medium'), 
            local('ArchivoNarrow-Medium'), 
            url(https://fonts.gstatic.com/s/archivonarrow/v12/tss3ApVBdCYD5Q7hcxTE1ArZ0b4Dqmld-9ICPRBv.woff2) format('woff2')
          `,
            unicodeRange:
              'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
          },
          {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 400,
            src: `
            local('Roboto'), 
            local('Roboto-Regular'), 
            url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2),
            local('sans-serif') format('woff2')
          `,
            unicodeRange:
              'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
          },
          {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 500,
            src: `
            local('Roboto Medium'), 
            local('Roboto-Medium'), 
            url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2),
            local('sans-serif-medium') format('woff2')
          `,
            unicodeRange:
              'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
          },
        ],
      },
    },
    MuiFilledInput: {
      root: {
        height: '40px',
        backgroundColor: color.primary.dark,
        borderRadius: '6px',
      },
      input: {
        paddingTop: 0,
        paddingBottom: 0,
        height: '100%',
        fontFamily: 'Roboto, sans-serif',
        color: '#fff',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        borderRadius: '6px',
        '&::placeholder': {
          opacity: 1,
          color: color.secondary.light,
        },
      },
      underline: {
        '&::before, &::after': {
          content: 'none',
        },
      },
    },
    MuiInputBase: {
      input: {
        '&:focus': {
          border: `1px solid ${color.primary.light}`,
        },
      },
    },
    MuiSelect: {
      icon: {
        color: color.secondary.light,
        top: 'calc(50% - 10px)',
      },
      selectMenu: {
        height: '40px',
        lineHeight: '40px',
      },
    },
    MuiButton: {
      contained: {
        '&:hover': {
          backgroundColor: 'none',
        },
      },
      root: {
        textTransform: 'initial',
        '&:hover': {
          backgroundColor: 'none',
        },
      },
      text: {
        padding: 0,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '14px',
        lineHeight: '26px',
        fontWeight: 500,
        color: '#fff',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '12px',
        color: color.secondary.dark,
        fontWeight: 500,
        marginLeft: 0,
        marginRight: 0,
        position: 'absolute',
        top: '100%',
      },
    },
  },
})

export default theme
