import React, { useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, Paper } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { matcher } from 'match-highlight-texts'
import Fuse from 'fuse.js'
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete'

// Styles
import { useStyles } from './useStyles'

type AutoSuggestProps = {
  value: string[]
  helpText?: string
  placeholder?: string
  isDisabled?: boolean
  isError?: boolean
  updateValue: (keyword: string[]) => void
  options: string[] | null
  counts: number
  updateCounts: (counts: number) => void
}

function MultipleAutoSuggest({
  value,
  updateValue,
  options,
  counts,
  updateCounts,
  helpText,
  isError,
  placeholder = '',
  isDisabled = false,
}: AutoSuggestProps): React.ReactElement {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (event: React.ChangeEvent<{}>, value: string): void => {
    setInputValue(value)

    // Reset counts
    if (counts !== 20) {
      updateCounts(20)
    }
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string[]): void => {
    if (value.length > newValue.length) {
      updateValue(newValue)
    } else {
      const latestValue = newValue[newValue.length - 1]
      // Fixed that pressing enter key bugs
      if (latestValue && options && options.findIndex((option) => option === latestValue) !== -1) {
        updateValue(newValue)
      }
    }
  }

  const filterOptions = (options: string[], state: FilterOptionsState<string>): string[] => {
    const removeLoadMore = options.filter((option) => !/more results/.test(option))
    const removeSpaceKeyword = state.inputValue
      .trimEnd()
      .trimStart()
      .replace(/[^a-zA-Z\d ]/g, '')

    if (!removeSpaceKeyword.length) {
      if (removeLoadMore.length > counts) {
        return [...removeLoadMore.slice(0, counts), `${removeLoadMore.length - counts} more results, view 20 more`]
      }

      return removeLoadMore
    }

    // 先搜尋 exact match，在搜尋 fuzzy match
    const keywordRegex = new RegExp(removeSpaceKeyword.replace(/ /g, '|'), 'i')
    const keywordRegexList = removeSpaceKeyword.split(' ').map((keyword) => new RegExp(keyword, 'i'))
    const exactMatchArray: string[] = []
    const fuzzyMatchArray: string[] = []

    removeLoadMore.forEach((item) => {
      if (keywordRegex.test(item)) {
        exactMatchArray.push(item)
      } else {
        fuzzyMatchArray.push(item)
      }
    })
    const exactMatchResult =
      keywordRegexList.length > 1
        ? exactMatchArray.sort((a, b) => {
            // 比對到的數量越多，優先序越高
            const aMatches = keywordRegexList.filter((keyword) => keyword.test(a))
            const aLengths = aMatches.length
            const bMatches = keywordRegexList.filter((keyword) => keyword.test(b))
            const bLengths = bMatches.length
            if (aLengths > bLengths) {
              return -1
            } else if (aLengths < bLengths) {
              return 1
            } else {
              return recursive(0)
            }

            function recursive(i: number): number {
              const aIndex = a.search(keywordRegexList[i])
              const bIndex = b.search(keywordRegexList[i])
              if (aIndex === -1 && bIndex === -1) {
                if (i < keywordRegexList.length - 1) {
                  return recursive(++i)
                }
                return 0
              } else if (aIndex === -1) {
                return 1
              } else if (bIndex === -1) {
                return -1
              } else if (aIndex < bIndex) {
                return -1
              } else if (aIndex > bIndex) {
                return 1
              } else {
                return 0
              }
            }
          })
        : exactMatchArray

    const fuse = new Fuse(fuzzyMatchArray, {
      includeScore: true,
      ignoreLocation: true,
    })
    const fuzzyMatchResult = fuse.search(state.inputValue.toUpperCase())
    const combinedMatchResult = [...exactMatchResult, ...fuzzyMatchResult.map((item) => item.item)]

    if (combinedMatchResult.length > counts) {
      return [...combinedMatchResult.slice(0, counts), `${combinedMatchResult.length - counts} more results, view 20 more`]
    }

    return combinedMatchResult
  }

  return (
    <Autocomplete
      className={classes.root}
      multiple
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={options || []}
      disableClearable
      fullWidth
      autoHighlight
      disabled={isDisabled}
      filterOptions={filterOptions}
      getOptionDisabled={(option): boolean => /more result/.test(option)}
      PaperComponent={(param): React.ReactElement => <Paper {...param} className={`${param.className} ${classes.paper}`} />}
      openOnFocus
      ChipProps={{
        className: classes.chip,
        deleteIcon: <CloseIcon />,
      }}
      ListboxProps={{
        className: `MuiAutocomplete-listbox ${classes.listBox}`,
        onClick: (event: React.SyntheticEvent): void => {
          const target = event.target as HTMLElement
          // Because the more result option is disabled, the event will stop at it's parent UL
          if (target.nodeName === 'UL') {
            updateCounts(counts + 20)
          }
        },
      }}
      renderOption={(option, { inputValue }): JSX.Element => {
        let divProps = {}
        if (/more results/.test(option)) {
          divProps = {
            className: classes.loadMore,
          }
        }

        return (
          <div {...divProps}>
            {matcher(option, inputValue).map((item) => (item.highlight ? <span className={classes.highlight}>{item.item}</span> : item.item))}
          </div>
        )
      }}
      renderInput={(params): React.ReactNode => (
        <TextField
          variant='filled'
          {...params}
          inputProps={{
            ...params.inputProps,
            size: inputValue.length + 5,
          }}
          onKeyPress={(event): void => {
            if (event.key === 'Enter') {
              event.preventDefault()
            }
          }}
          placeholder={placeholder}
          helperText={helpText || ''}
          className={`${classes.helper} ${isError ? classes.error : ''}`}
          autoComplete='off'
        />
      )}
    />
  )
}

export default MultipleAutoSuggest
