import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '30px',

    '& .MuiFilledInput-root': {
      height: 'auto',
      minHeight: '40px',
    },

    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
      padding: '0px 12px',
      maxHeight: 'calc(100vh - 64px - 420px)',
      overflow: 'auto',
      '& .MuiAutocomplete-input': {
        height: '40px',
        boxSizing: 'border-box',
        width: 'auto',
        paddingLeft: 0,
        paddingRight: 0,
        maxWidth: '100%',
        '&:focus': {
          borderColor: 'transparent',
        },
      },
    },

    '& .Mui-disabled': {
      cursor: 'not-allowed',
    },

    '& .MuiAutocomplete-endAdornment': {
      '& svg': {
        color: theme.palette.secondary.light,
        width: '20px',
        height: '20px',
      },
    },
  },
  chip: {
    padding: '0px 10px',
    color: theme.palette.background.default,
    background: theme.palette.primary.light,
    fontSize: '12px',
    cursor: 'pointer',
    margin: '4px 9px 4px 0px',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    height: 'auto',
    '& .MuiChip-deleteIcon': {
      width: '12px',
      height: '12px',
      color: 'inherit',
    },
  },
  listBox: {
    maxHeight: '300px',
    padding: 0,
    '& li': {
      opacity: '1 !important',
      color: theme.palette.secondary.light,
      fontSize: '14px',
      fontWeight: 400,
      background: theme.palette.primary.dark,

      '&[aria-selected="true"]': {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.primary.contrastText,
        fontWight: 600,
      },

      '&:hover, &[data-focus="true"]': {
        color: theme.palette.background.default,
        background: theme.palette.primary.light,
      },
    },
  },
  loadMore: {
    color: theme.palette.primary.light,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    fontSize: '12px',
  },
  paper: {
    background: theme.palette.primary.dark,
  },
  highlight: {
    fontWeight: 'bold',
    color: theme.palette.background.default,
  },
  helper: {
    position: 'relative',

    '& .MuiFormHelperText-root': {
      fontSize: '12px',
      color: theme.palette.secondary.dark,
      fontWeight: 500,
      marginLeft: 0,
      marginRight: 0,
      position: 'absolute',
      top: '100%',
    },
  },
  error: {
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.light,
    },
  },
}))
