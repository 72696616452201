import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSelect-select:focus': {
      borderColor: 'transparent',
    },

    '& svg': {
      width: '20px',
      height: '20px',
      top: 'calc(50%  - 10px)',
    },
  },
  menu: {
    height: '396px',
    '& .MuiPaper-root': {
      background: theme.palette.primary.dark,
    },
    '& .MuiList-root': {
      padding: 0,
      color: theme.palette.secondary.light,
    },
    '& .MuiMenuItem-root': {
      paddingTop: 6,
      paddingBottom: 6,
      margin: 0,
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Roboto',
      '&:hover': {
        background: theme.palette.primary.light,
        color: theme.palette.background.default,
      },
    },
  },
  selectLabel: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '13px',
    color: theme.palette.secondary.light,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    borderRadius: '6px',
    cursor: 'pointer',
  },
}))
