import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
      overflow: 'visible',
    },
  },
  background: {
    '& img': {
      width: '100%',
      transform: 'scale(1.10)',
    },
    [theme.breakpoints.down('md')]: {
      '& img': {
        transform: 'scale(1)',
      },
    },
  },
  highlight: {
    paddingLeft: '6px',
    color: theme.palette.primary.light,
    fontWeight: 500,
    fontSize: '26px',
  },
  chip: {
    padding: '5px 10px',
    color: theme.palette.background.default,
    background: theme.palette.primary.dark,
    fontSize: '12px',
    lineHeight: '14px',
    cursor: 'pointer',
    marginRight: '9px',
    marginBottom: '8px',
    '&:hover': {
      background: theme.palette.secondary.dark,
    },
    '&[data-active="true"]': {
      background: theme.palette.primary.light,
    },
  },
  submitButton: {
    color: theme.palette.background.default,
    borderRadius: '6px',
    backgroundColor: theme.palette.primary.light,
    paddingTop: '6px',
    paddingBottom: '6px',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '28px',
  },
}))
