import { Range, Condition } from '../constants'

export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp]

export const getToken = (): string | null => {
  const token = localStorage.getItem('token')
  if (token === null) {
    return token
  }

  return atob(token)
}

export const removeToken = (): void => {
  localStorage.removeItem('token')
}

async function callApi<T>(url: RequestInfo, options: RequestInit = {}): Promise<T> {
  const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  })
  if (response.status >= 400 && response.status < 600) {
    if (response.status === 401) {
      const token = getToken()
      if (token) {
        removeToken()
      }
    }
    throw new Error('There are some unknown errors')
  }
  return await response.json()
}

export default callApi

export const fetchSheet = () => callApi<{ sid: string; name: string }[]>('adac/sheet_list/')

export const fetchAssetNameList = (sid: string): Promise<string[]> =>
  callApi<{ asset_name: string; company_name: string }[]>(`adac/asset_list/?sid=${sid}`).then((data) =>
    data
      .map((item) => {
        if (item.company_name) {
          return `${item.asset_name} - ${item.company_name}`
        }
        return item.asset_name
      })
      .sort(),
  )

export type BackendFilterType =
  | {
      filter: string
      operator: Condition
      type: 'string'
      range: ''
      value: string[]
    }
  | ({
      filter: string
      operator: Condition
      type: 'time'
      value: []
    } & (
      | { range: { [key in Extract<keyof typeof Range, 'bt' | 'ot'>]: [string, string] } }
      | { range: { [key in Exclude<keyof typeof Range, 'bt' | 'ot'>]: string } }
    ))
  | ({
      filter: string
      operator: Condition
      type: 'number'
      value: []
    } & (
      | { range: { [key in Extract<keyof typeof Range, 'bt' | 'ot'>]: [number, number] } }
      | { range: { [key in Exclude<keyof typeof Range, 'bt' | 'ot'>]: number } }
    ))

type BackendTagType = {
  tag_id: string
  tag_name: string
  sheet_id: string
  order: number
  sheet_name: string
  filters: BackendFilterType[]
}

export type FrontendFilterType = {
  key: PropType<BackendFilterType, 'filter'>
  condition: PropType<BackendFilterType, 'operator'>
  min: string
  max: string
} & ({ filterType: 'string'; value: string[] } | { filterType: 'number' | 'time'; value: keyof typeof Range })

export type FrontendTagType = {
  tagId: string
  tagName: string
  tagSort: number
  sheetId: string
  sheetName: string
  filters: FrontendFilterType[]
}

export const fetchTagList = (): Promise<FrontendTagType[]> =>
  callApi<BackendTagType[]>('adac/tags_list/?filters=true').then((data) =>
    data
      .map((item) => ({
        tagId: item.tag_id,
        tagName: item.tag_name,
        tagSort: item.order,
        sheetId: item.sheet_id,
        sheetName: item.sheet_name,
        filters: item.filters
          .filter((filter) => Object.keys(filter).length)
          .map(
            (filter): FrontendFilterType => {
              if (filter.type === 'string') {
                return { filterType: filter.type, min: '', max: '', value: filter.value, condition: filter.operator, key: filter.filter }
              } else if (filter.type === 'number') {
                const [range, value] = Object.entries(filter.range)[0] as [keyof typeof Range, number | [number, number]]
                if (typeof value === 'number') {
                  return { filterType: filter.type, condition: filter.operator, key: filter.filter, min: value.toString(), max: '', value: range }
                } else {
                  const [min, max] = value
                  return {
                    filterType: filter.type,
                    condition: filter.operator,
                    key: filter.filter,
                    min: min.toString(),
                    max: max.toString(),
                    value: range,
                  }
                }
              } else {
                const [range, value] = Object.entries(filter.range)[0] as [keyof typeof Range, string | [string, string]]
                if (typeof value === 'string') {
                  return { filterType: filter.type, condition: filter.operator, key: filter.filter, min: value, max: '', value: range }
                } else {
                  const [min, max] = value
                  return { filterType: filter.type, condition: filter.operator, key: filter.filter, min, max, value: range as keyof typeof Range }
                }
              }
            },
          ),
      }))
      .sort((a, b) => (a.tagSort > b.tagSort ? 1 : -1)),
  )

type RecordType = {
  name: string
  email: string
  sid: string
  filters: FrontendFilterType[]
}

export const record = ({ name, email, sid, filters }: RecordType): Promise<void> =>
  callApi('adac/record/', {
    method: 'POST',
    body: JSON.stringify({
      name,
      email,
      sid,
      filters: filters.map((filter): BackendFilterType => filterParser(filter)),
    }),
  })

export type FilterSetting = {
  head: string
  str_val: string[]
} & (
  | {
      type: 'time' | 'string'
      min: string
      max: string
    }
  | {
      type: 'number'
      min: number
      max: number
    }
)

export const fetchTopicFilterSetting = ({ sid, head }: { sid: string; head: string }) =>
  callApi<FilterSetting>('adac/filter_value/', { method: 'POST', body: JSON.stringify({ sid, head }) }).then((data) => ({
    ...data,
    str_val: data.str_val.sort((a, b) => (a > b ? 1 : -1)),
  }))

export const fetchHeads = ({ sid }: { sid: string }) => callApi<string[]>(`adac/filter_header?sid=${sid}`).then((data) => data.sort())

const filterConditionParser = (data: BackendFilterType[]): BackendFilterType[][] => {
  const addList: BackendFilterType[] = []
  const orList: BackendFilterType[][] = []

  data.forEach((item, index, array) => {
    if (item.operator === Condition.and) {
      // 如果下一個是 or，則表示這個條件和下一個是擇一
      if (array[index + 1]?.operator === Condition.or) {
        orList.push([item])
      } else {
        addList.push(item)
      }
    } else {
      orList[orList.length - 1].push(item)
    }
  })

  return orList.reduce(
    (accumulateFirst, currentFirst) => {
      return currentFirst.reduce((accumulateSecond: BackendFilterType[][], currentSecond) => {
        const a = accumulateFirst.map((accumulateItem) => [...accumulateItem, currentSecond])
        return [...accumulateSecond, ...a]
      }, [])
    },
    [addList],
  )
}

export const mail = ({ name, email, sid, filters }: RecordType): Promise<void> =>
  callApi('adac/email/', {
    method: 'POST',
    body: JSON.stringify({
      name,
      email,
      sid,
      filters: filterConditionParser(filters.map((filter): BackendFilterType => filterParser(filter))),
    }),
  })

function dateFormatTransfer(dateString: string): string {
  return new Date(`${dateString} 00:00:00`)
    .toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
    .replace(/ /g, '-')
}

export const filterParser = (filter: FrontendFilterType): BackendFilterType => {
  if (filter.filterType === 'string') {
    return { type: filter.filterType, range: '', value: filter.value, operator: filter.condition, filter: filter.key }
  } else if (filter.filterType === 'number') {
    const value = filter.value as keyof typeof Range
    if (value !== 'bt' && value !== 'ot') {
      const range = { [value]: parseFloat(filter.min) } as { [key in Exclude<keyof typeof Range, 'bt' | 'ot'>]: number }
      return { type: filter.filterType, value: [], operator: filter.condition, filter: filter.key, range }
    } else {
      const range = { [value]: [parseFloat(filter.min), parseFloat(filter.max)] } as {
        [key in Extract<keyof typeof Range, 'bt' | 'ot'>]: [number, number]
      }
      return { type: filter.filterType, value: [], operator: filter.condition, filter: filter.key, range }
    }
  } else {
    const value = filter.value as keyof typeof Range
    if (value !== 'bt' && value !== 'ot') {
      const range = { [value]: dateFormatTransfer(filter.min) } as { [key in Exclude<keyof typeof Range, 'bt' | 'ot'>]: string }
      return { type: filter.filterType, value: [], operator: filter.condition, filter: filter.key, range }
    } else {
      const range = { [value]: [dateFormatTransfer(filter.min), dateFormatTransfer(filter.max)] } as {
        [key in Extract<keyof typeof Range, 'bt' | 'ot'>]: [string, string]
      }
      return { type: filter.filterType, value: [], operator: filter.condition, filter: filter.key, range }
    }
  }
}

export const fetchResult = ({ sid, filters }: { sid: string; filters: FrontendFilterType[] }) => {
  const filtersConversion = filterConditionParser(filters.map(filterParser))

  return callApi<{ header: string[]; items: { name: string; rows: { [key: string]: string }[]; [key: string]: unknown }[]; x: string; y: string }>(
    'adac/preview/',
    {
      method: 'POST',
      body: JSON.stringify({
        sid,
        filters: filtersConversion,
      }),
    },
  )
}

export const createTag = ({ tags }: { tags: FrontendTagType[] }) =>
  callApi<void>('adac/tags_modify/', {
    method: 'POST',
    body: JSON.stringify(
      tags.map((tag) => ({
        sid: tag.sheetId,
        tag_id: tag.tagId,
        tag_name: tag.tagName,
        filters: tag.filters.map((filter) => filterParser(filter)),
        order: tag.tagSort,
      })),
    ),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${getToken() ?? ''}`,
    },
  })

export const changeTag = ({ tags }: { tags: FrontendTagType[] }) =>
  callApi<void>(`adac/tags_modify/`, {
    method: 'PUT',
    body: JSON.stringify(
      tags.map((tag) => ({
        sid: tag.sheetId,
        tag_id: tag.tagId,
        tag_name: tag.tagName,
        filters: tag.filters.map((filter) => filterParser(filter)),
        order: tag.tagSort,
      })),
    ),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${getToken() ?? ''}`,
    },
  })

export const deleteTag = ({ tags, id }: { tags: FrontendTagType[]; id: string }) => {
  return callApi<void>(`adac/tags_modify/`, {
    method: 'DELETE',
    body: JSON.stringify(
      tags.map((tag) => ({
        sid: tag.sheetId,
        tag_id: tag.tagId,
        tag_name: tag.tagName,
        filters: tag.filters.map((filter) => filterParser(filter)),
        order: tag.tagId === id ? 0 : tag.tagSort,
      })),
    ),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${getToken() ?? ''}`,
    },
  })
}

export const fetchGoogleSheet = () =>
  callApi<{ sid: string; name: string }[]>('parser/update_gsheet/', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${getToken() ?? ''}`,
    },
  })
export const postSyncSheets = (sheets: { sid: string; name: string }[]) =>
  callApi<{ sid: string; name: string }[]>('parser/update_gsheet/', {
    method: 'POST',
    body: JSON.stringify({ gsheet_list: sheets }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${getToken() ?? ''}`,
    },
  })

export const login = ({ username, password }: { username: string; password: string }) =>
  callApi<{ token: string; user_id: number; email: string }>('adac/login/', {
    method: 'POST',
    body: JSON.stringify({ username, password }),
  }).then((data) => ({ token: data.token, email: data.email, userId: data.user_id }))
