import React, { useRef, useState, useEffect } from 'react'
import { Box, TextField, MenuItem, InputLabel, Typography } from '@material-ui/core'

// Styles
import { useStyles } from './useStyles'

// Types
type SelectProps = {
  label: string
  value: string
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  options: Array<{ key: string; value: string }>
  onChange: (value: string) => void
  placeholder: string
  helpText?: string
  className?: string
}

function Select({
  label,
  value,
  isOpen,
  onOpen,
  onClose,
  onChange,
  options,
  placeholder,
  className = '',
  helpText = '',
}: SelectProps): React.ReactElement {
  const classes = useStyles()
  const selectRef = useRef<{ node: HTMLInputElement; value: string; focus: Function }>()
  const [maxWidth, setMaxWidth] = useState('100%')

  useEffect(() => {
    setMaxWidth(`${selectRef.current?.node?.offsetWidth}px`)
  }, [])

  const handleOpen = (): void => {
    onOpen()
    selectRef.current?.focus()
  }

  const handleCancel = (): void => {
    onClose() // TODO: 後來對 xstate 參悟，其實是不需要 onClose 還有 setTimeout，因為 change function 之後會進到其他 state，自然就會關掉了
    setTimeout(() => {
      const activeDom = document.activeElement as HTMLElement
      if (activeDom) {
        activeDom.blur()
      }
    }, 0)
  }

  const handleChange = (event: React.ChangeEvent<{ value: string }>): void => {
    onChange(event?.target.value)
  }

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Box position='relative' mb='30px'>
        <TextField
          select
          fullWidth
          variant='filled'
          className={`${classes.root} ${className}`}
          SelectProps={{
            open: isOpen,
            onOpen: handleOpen,
            onClose: handleCancel,
            MenuProps: {
              className: classes.menu,
              style: { maxWidth },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          }}
          helperText={helpText}
          value={value}
          onChange={handleChange}
          inputRef={selectRef}
        >
          {options.map((option) => (
            <MenuItem key={option.key} value={option.key}>
              <Typography variant='inherit' noWrap>
                {option.value}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
        {/* The Material UI Select component does not have placeholder, so the label below is used for simulation  */}
        {value ? null : (
          <InputLabel className={classes.selectLabel} onClick={handleOpen}>
            {placeholder}
          </InputLabel>
        )}
      </Box>
    </>
  )
}

export default Select
