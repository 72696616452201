import React, { useRef, useEffect } from 'react'
import { Box, Button, InputLabel, TextField, Typography, Chip } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useMachine } from '@xstate/react'
import { useHistory } from 'react-router'

// Components
import Select from '../../components/basic/Select'
import Loading from '../../components/basic/Loading'
import MultipleAutoSuggest from '../../components/basic/MultipleAutoSuggest'
import ErrorMessage from '../../components/basic/ErrorMessage'
import Br from '../../components/basic/Br'

// States
import { machineConfig } from './machineConfig'

// Styles and Images
import Logo from '../../assets/images/logo-black.svg'
import { useStyles } from './useStyles'

// Constants
import { Condition } from '../../constants'

const Home: React.FC = () => {
  const classes = useStyles()
  const [state, send] = useMachine(machineConfig)
  const history = useHistory()
  const recaptchaContainer = useRef<HTMLDivElement>(null)
  const grecaptcha = Boolean(window.grecaptcha && window.grecaptcha?.render) ? window.grecaptcha : null

  useEffect(() => {
    if (grecaptcha && grecaptcha.render) {
      grecaptcha.render(recaptchaContainer.current as HTMLDivElement, {
        sitekey: process.env.REACT_APP_RECAPTCHA_KEY as string,
        'expired-callback': (): void => {
          send({ type: 'CHANGE_RECAPTCHA', data: false })
        },
      })
    }
  }, [send, grecaptcha])

  const onSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault()
    if (window.grecaptcha.getResponse() && !state.context.recaptcha) {
      send({ type: 'CHANGE_RECAPTCHA', data: true })
    }

    const { context, done } = send('VALIDATE')
    if (done) {
      const { email, keyword, name, sid, tag } = context
      const submitData = JSON.stringify({
        name,
        email,
        sid,
        filters: JSON.stringify(
          tag
            ? tag.filters
            : [
                {
                  filterType: 'string',
                  value: keyword.map((item) => item.split('-')[0].trim()),
                  condition: Condition.and,
                  key: 'ASSET NAME',
                  min: '',
                  max: '',
                },
              ],
        ),
      })
      history.push(`/result/?search=${window.btoa(submitData)}`)
    }
  }

  return (
    <Box display='flex' className={classes.root}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        flex={{ sm: 'none', md: '4.6 1 0' }}
        minWidth={{ sm: '100%', md: '580px' }}
        height={{ sm: 'auto', md: '100%' }}
      >
        <Box px={{ xs: '20px', md: '28px' }} py={{ xs: '30px', md: '60px' }}>
          <Button component={Link} to='/'>
            <img src={Logo} alt='adac logo' />
          </Button>
          <Box mt='45px'>
            <Typography component='h1' variant='h1'>
              ADVANCED DATA ANALYTICS CO. (ADAC)
            </Typography>
          </Box>
          <Box mt='44px' pb='9px'>
            <Typography component='h2' variant='h2' color='primary'>
              DATA ANALYTICS AND VISUALIZATION
            </Typography>
          </Box>
          <Br color='#173B98' />
          <Box py='13px'>
            <Typography component='h3' variant='h3' color='secondary'>
              FROM DATA TO RELEVANT, TIMELY, ACTIONABLE INFORMATION
            </Typography>
          </Box>
        </Box>
        <Box className={classes.background} width={1} overflow={{ md: 'visible', lg: 'hidden' }}>
          <picture>
            <source srcSet={require('../../assets/images/background.webp')} type='image/webp' />
            <img src={require('../../assets/images/background.png')} alt='background' />
          </picture>
        </Box>
      </Box>
      <Box
        flex={{ sm: 'none', md: '5.4 0 0' }}
        position='relative'
        minWidth={{ sm: '100%', md: '786px' }}
        overflow='auto'
        py='40px'
        px={{ xs: '30px', md: '102px', lg: '152px' }}
        bgcolor='#1A2B4D'
        color='#fff'
      >
        {state.matches('select.loading') || state.matches('edit.loading') ? <Loading /> : null}

        <Typography variant='h4' component='h4'>
          Welcome to
          <Typography component='span' variant='body1' className={classes.highlight}>
            ADAC
          </Typography>
        </Typography>
        <form autoComplete='off' noValidate onSubmit={onSubmit}>
          <Box mt='40px' mb='30px'>
            <Box position='relative'>
              <Select
                label='Topic'
                value={state.context.sid}
                isOpen={state.matches('select.loaded.open')}
                onOpen={(): void => {
                  send('OPEN')
                }}
                onClose={(): void => {
                  send('CLOSE')
                }}
                options={state.context.sheets}
                onChange={(data): void => {
                  send({ type: 'CHANGE_SID', data })
                }}
                placeholder='Select Asset Group'
              />
              <ErrorMessage show={state.matches('edit.failure.sheet')} message={state.meta['home.edit.failure.sheet']?.message} top='69px' />
            </Box>
            <InputLabel>Criteria</InputLabel>
            <Box position='relative'>
              <MultipleAutoSuggest
                value={state.context.keyword}
                updateValue={(keyword: string[]): void => {
                  send({ type: 'CHANGE_KEYWORD', data: keyword, keywordType: 'assetName' })
                }}
                options={state.context.assetNames}
                counts={state.context.assetNameCounts}
                updateCounts={(counts: number): void => {
                  send({ type: 'CHANGE_ASSET_NAME_COUNTS', data: counts })
                }}
                isDisabled={!state.context.sid}
                placeholder={state.context.keyword.length === 0 ? 'Please select topic first' : ''}
              />
              <ErrorMessage show={'keyword' in state.context.errorMessage} message={state.context.errorMessage.keyword} top='35px' />
              <ErrorMessage
                show={state.matches('edit.failure.assetName') || state.matches('edit.failure.tag')}
                message={(state.meta['home.edit.failure.assetName'] || state.meta['home.edit.failure.tag'])?.message}
                top='35px'
              />
            </Box>
            {state.context.tagList.length ? (
              <>
                <InputLabel>Popular Search</InputLabel>
                <Box display='flex' flexWrap='wrap' mt='5px'>
                  {state.context.tagList.map((item) => (
                    <Chip
                      key={item.tagId}
                      size='small'
                      label={item.tagName}
                      className={classes.chip}
                      onClick={(): void => {
                        send({ type: 'CHANGE_KEYWORD', data: item, keywordType: 'tagName' })
                      }}
                      data-active={state.context.keywordType === 'tagName' && state.context.keyword.some((keyword) => keyword === item.tagName)}
                    />
                  ))}
                </Box>
              </>
            ) : null}
          </Box>
          <Br color='#A2A7B9' />
          <Box pt='30px'>
            <InputLabel>Your Information</InputLabel>
            <Box mb='26px' position='relative'>
              <TextField
                fullWidth
                placeholder='Your Name'
                variant='filled'
                value={state.context.name}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
                  send({ type: 'CHANGE_NAME', data: event.target.value })
                }}
              />
              <ErrorMessage show={'name' in state.context.errorMessage} message={state.context.errorMessage.name} top='35px' />
            </Box>
            <Box position='relative'>
              <TextField
                fullWidth
                placeholder='Your Email'
                variant='filled'
                value={state.context.email}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
                  send({ type: 'CHANGE_EMAIL', data: event.target.value })
                }}
              />
              <ErrorMessage show={'email' in state.context.errorMessage} message={state.context.errorMessage.email} top='35px' />
            </Box>

            <Box mt='30px' mb='28px' position='relative'>
              <div ref={recaptchaContainer}></div>
              <ErrorMessage show={'recaptcha' in state.context.errorMessage} message={state.context.errorMessage.recaptcha} top='72px' />
            </Box>

            <Button type='submit' fullWidth variant='contained' className={classes.submitButton}>
              Search
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}

export default Home
