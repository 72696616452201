import React from 'react'
import { Box, BoxProps } from '@material-ui/core'

type BrProps = BoxProps & {
  color: string
  path?: 'horizontal' | 'vertical'
}

const Br = ({ color, path = 'horizontal', ...restProps }: BrProps) => {
  const boxProp: BoxProps = { ...restProps, bgcolor: color }

  if (path === 'horizontal') {
    boxProp.width = 1
    boxProp.height = '1px'
  } else {
    boxProp.width = '1px'
    boxProp.height = 1
  }

  return <Box {...boxProp} />
}

export default Br
