import { Machine, assign } from 'xstate'

import { fetchSheet, fetchAssetNameList, fetchTagList, FrontendTagType, record } from '../../utils/api'

import { Condition } from '../../constants'

// eslint-disable-next-line
const EMAIL_REGNEXG = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i

type HomeContext = {
  sheets: { key: string; value: string }[]
  assetNames: string[] | null
  assetNameCounts: number
  sid: string
  name: string
  email: string
  keyword: string[]
  tag?: {
    id: string
  } & Pick<FrontendTagType, 'filters'>
  keywordType: 'assetName' | 'tagName'
  recaptcha: boolean
  tagList: FrontendTagType[]
  errorMessage: { [key: string]: string }
}

export const machineConfig = Machine<HomeContext>(
  {
    id: 'home',
    initial: 'edit',
    context: {
      sheets: [],
      assetNames: null,
      assetNameCounts: 20,
      sid: '',
      name: '',
      email: '',
      recaptcha: false,
      keyword: [],
      keywordType: 'assetName',
      errorMessage: {},
      tagList: [],
    },
    states: {
      edit: {
        id: 'edit',
        initial: 'initialize',
        states: {
          initialize: {
            type: 'parallel',
            states: {
              fetchTagList: {
                initial: 'pending',
                states: {
                  pending: {
                    invoke: {
                      src: 'fetchTagList',
                      onDone: {
                        target: 'success',
                        actions: 'tagListChangeAction',
                      },
                      onError: {
                        target: '#home.edit.failure.tag',
                      },
                    },
                  },
                  success: {
                    type: 'final',
                  },
                },
              },
              fetchSheet: {
                initial: 'pending',
                states: {
                  pending: {
                    invoke: {
                      src: 'fetchSheet',
                      onDone: {
                        target: 'success',
                        actions: 'sheetDoneHandler',
                      },
                      onError: {
                        target: '#home.edit.failure.sheet',
                      },
                    },
                  },
                  success: {
                    type: 'final',
                  },
                },
              },
            },
            onDone: {
              target: 'idle',
            },
          },
          idle: {},
          loading: {
            invoke: {
              src: 'fetchAssetNames',
              onDone: {
                target: 'loaded',
                actions: 'assetNameDoneHandler',
              },
              onError: {
                target: 'failure.assetName',
              },
            },
          },
          loaded: {},
          failure: {
            states: {
              sheet: {
                meta: {
                  message: 'Failed to get the sheet list',
                },
              },
              assetName: {
                meta: {
                  message: 'Failed to get the asset name list',
                },
              },
              tag: {
                meta: {
                  message: 'Failed to get the tag list',
                },
              },
            },
          },
          validate: {
            always: {
              target: '#home.finish',
              cond: 'isValid',
            },
          },
        },
      },
      select: {
        id: 'select',
        initial: 'loaded',
        states: {
          loaded: {
            id: 'loaded',
            initial: 'open',
            states: {
              open: {
                on: {
                  CLOSE: 'close',
                },
              },
              close: {
                on: {
                  OPEN: 'open',
                },
              },
            },
          },
        },
      },
      tags: {},
      finish: {
        type: 'final',
        invoke: {
          src: 'postRecord',
        },
      },
    },
    on: {
      OPEN: { target: 'select' },
      TAGS: {
        target: 'tags',
      },
      CHANGE_SID: {
        target: 'edit.loading',
        actions: 'changeSid',
      },
      CHANGE_NAME: {
        target: 'edit.idle',
        actions: 'changeName',
      },
      CHANGE_EMAIL: {
        target: 'edit.idle',
        actions: 'changeEmail',
      },
      CHANGE_KEYWORD: [
        {
          target: 'edit.loading',
          cond: 'keywordIsTagName',
          actions: ['deleteKeyword', 'changeKeyword'],
        },
        {
          target: 'edit.idle',
          cond: 'keywordTypeIsSame',
          actions: ['deleteKeyword', 'changeKeyword'],
        },
        {
          target: 'edit.idle',
          actions: 'changeKeyword',
        },
      ],
      CHANGE_RECAPTCHA: {
        target: 'edit.idle',
        actions: 'changeRecaptcha',
      },
      VALIDATE: [
        {
          target: 'edit.validate',
          actions: 'validate',
        },
      ],
      CHANGE_ASSET_NAME_COUNTS: {
        target: 'edit.idle',
        actions: 'changeAssetNameCounts',
      },
    },
  },
  {
    guards: {
      isValid: (context): boolean => Object.keys(context.errorMessage).length === 0,
      keywordTypeIsSame: (context, event): boolean => Boolean(context.keywordType !== event.keywordType),
      keywordIsTagName: (context, event): boolean => Boolean(event.keywordType === 'tagName'),
    },
    actions: {
      sheetDoneHandler: assign({ sheets: (context, event) => event.data }),
      assetNameDoneHandler: assign({ assetNames: (context, event) => event.data }),
      assetNameFailureHandler: assign({
        errorMessage: (context, event) => ({ ...context.errorMessage, keyword: event.data.message }),
        assetNames: (context) => [] as string[],
      }),
      changeSid: assign({
        sid: (context, event) => event.data,
        keyword: (context) => [] as string[],
        errorMessage: (context) => {
          delete context.errorMessage.keyword
          return context.errorMessage
        },
      }),
      changeName: assign({
        name: (context, event) => event.data,
        errorMessage: (context) => {
          delete context.errorMessage.name
          return context.errorMessage
        },
      }),
      changeEmail: assign({
        email: (context, event) => event.data,
        errorMessage: (context) => {
          delete context.errorMessage.email
          return context.errorMessage
        },
      }),
      changeKeyword: assign({
        keyword: (context, event) => {
          if (event.keywordType === 'tagName') {
            const data = event.data.tagName
            return [data]
          } else {
            const data: string[] = event.data

            if (context.keyword.length === 0 && data.length > 1) {
              data.shift()
            }

            return data
          }
        },
        errorMessage: (context) => {
          delete context.errorMessage.keyword
          return context.errorMessage
        },
        sid: (context, event) => {
          if (event.keywordType === 'tagName') {
            return event.data.sheetId
          }
          return context.sid
        },
        tag: (context, event) => {
          if (event.keywordType === 'tagName') {
            return { id: event.data.tagId, filters: event.data.filters }
          } else {
            return undefined
          }
        },
      }),
      changeRecaptcha: assign({
        recaptcha: (context, event) => event.data,
        errorMessage: (context) => {
          delete context.errorMessage.recaptcha
          return context.errorMessage
        },
      }),
      changeAssetNameCounts: assign({
        assetNameCounts: (context, event) => event.data,
      }),
      deleteKeyword: assign({
        keywordType: (context, event) => event.keywordType,
        keyword: (context, event) => [] as string[],
        errorMessage: (context) => {
          delete context.errorMessage.keyword
          return context.errorMessage
        },
      }),
      tagListChangeAction: assign({
        tagList: (context, event) => event.data,
      }),
      validate: assign({
        errorMessage: (context) => {
          let errorObject: { [key: string]: string } = {}
          if (!EMAIL_REGNEXG.test(context.email)) {
            errorObject.email = 'Please enter valid email'
          }

          if (context.name.length === 0) {
            errorObject.name = 'Please enter your name'
          }

          if (context.keyword.length === 0) {
            errorObject.keyword = 'Please select at least one search criteria'
          }

          if (!context.recaptcha) {
            errorObject.recaptcha = 'Please check you are not robot'
          }

          return { ...context.errorMessage, ...errorObject }
        },
      }),
      manuallyValidate: assign({
        errorMessage: (context, event) => ({ ...context.errorMessage, [event.errorName]: event.message }),
      }),
    },
    services: {
      postRecord: (context) =>
        record({
          sid: context.sid,
          name: context.name,
          email: context.email,
          filters: context.tag
            ? context.tag.filters
            : [{ filterType: 'string', value: context.keyword, condition: Condition.and, key: 'ASSET NAME', min: '', max: '' }],
        }),
      fetchSheet: () => fetchSheet().then((data) => data.map((item) => ({ key: item.sid, value: item.name }))),
      fetchAssetNames: (context) => fetchAssetNameList(context.sid),
      fetchTagList: (context) => fetchTagList(),
    },
  },
)
