export enum Condition {
  and = 'and',
  or = 'or',
}

export enum Range {
  'eq' = 'Equal to',
  'neq' = 'Not equal to',
  'gt' = 'Greater than',
  'gte' = 'Greater than or equal to',
  'lt' = 'Less than',
  'lte' = 'Less than or equal to',
  'bt' = 'In the range',
  'ot' = 'Out the range',
}
