import React from 'react'
import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group'
import { styled } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const AnimatedTransitionGroup = styled(TransitionGroup)({
  position: 'relative',
  width: '100%',
  flexGrow: 1,
})

enum STATUS {
  entering = 'entering',
  entered = 'entered',
  exiting = 'exiting',
}

interface AnimatedDIVProps {
  status: STATUS
  time: number
}

const AnimatedDIV = styled(Box)({
  height: '100%',
  opacity: ({ status }: AnimatedDIVProps): number => (status === STATUS.entered ? 1 : 0),
  position: ({ status }: AnimatedDIVProps): 'absolute' | 'static' => (status === STATUS.entering ? 'absolute' : 'static'),
  transition: ({ status, time }: AnimatedDIVProps): string =>
    status === STATUS.entering ? 'initial' : `opacity ${time}ms ease-in-out, transform ${time}ms ease-in-out`,
  transform: ({ status }: AnimatedDIVProps): string =>
    status === STATUS.entering ? 'translateX(50px)' : status === STATUS.entered ? 'translateX(0px)' : 'translateX(-50px)',
})

const TIMEOUT = 200

interface TransitionProps {
  children: React.ReactNode
  location: string
}

function Transition({ children, location }: TransitionProps): React.ReactElement {
  return (
    <AnimatedTransitionGroup>
      <ReactTransition
        key={location}
        timeout={{
          enter: TIMEOUT,
          exit: TIMEOUT,
        }}
      >
        {(status: STATUS): React.ReactElement => (
          <AnimatedDIV time={TIMEOUT} status={status}>
            {children}
          </AnimatedDIV>
        )}
      </ReactTransition>
    </AnimatedTransitionGroup>
  )
}

export default Transition
